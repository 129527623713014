<template>
  <v-container fluid>
    <v-card class="mx-auto pa-8">
      <v-card-text class="d-flex justify-center">
        <h1>Successfully submitted publication!</h1>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
export default {
  name: "CreatePublicationSuccess"
}
</script>

<style scoped>

</style>
